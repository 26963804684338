<script setup lang="ts">
import {CmsElementProductListing} from "@shopware-pwa/composables-next";
import {BevcoCurrentFilter} from "~/types/BevcoCurrentFilter";
import {ProductListingResult} from "@shopware-pwa/types";
import {
    AisInstantSearchSsr,
    AisConfigure,
    AisInfiniteHits,
    AisSortBy,
    AisClearRefinements,
    AisCurrentRefinements,
    AisPanel,
    AisRefinementList,
    AisRangeInput,
    AisStats,
    createServerRootMixin
} from 'vue-instantsearch/vue3/es';
import useAlgolia from "~/composables/useAlgolia";
import {useElementVisibility} from "@vueuse/core";
import {renderToString} from 'vue/server-renderer'
import {connectRefinementList, connectSortBy, connectInfiniteHits, connectRange} from 'instantsearch.js/es/connectors';
import AisRefresh from "~/components/algolia/AisRefresh.vue";

const props = defineProps<{
    content: CmsElementProductListing;
}>();

const {t} = useI18n();
const algoliaConfig = useRuntimeConfig().public.algoliaDev;
const indexName = algoliaConfig.productIndexName;
const listingElement = props.content.data.listing as ProductListingResult & { streamId: string | null }
const currentFilters = listingElement.currentFilters as BevcoCurrentFilter;
const availableFilters = ['Kategori', 'Alkohol', 'Certificering', 'Drue', 'Emballage', 'Land', 'Leverandør', 'Mærke', 'Region', 'Størrelse', 'Årgang'];
const algoliaProductFilterQuery = ref('');
const algolia = useAlgoliaRef();
const {insightsSettings, addSearchHistory, algoliaGetObject} = useAlgolia();
const {apiInstance} = useShopwareContext();
const prices = ref([]);
const {isLoggedIn, user} = useUser();
const loadMoreButton = ref(null);
const loadMoreButtonVisibility = useElementVisibility(loadMoreButton);
const userToken = ref(null);
const totalHits = ref(0)
const refreshItem = ref(null);
const isKey = ref(1);
const pageTitle = inject('pageTitle', null);


const filterData = ref({
    openFilter: '',
    showFilters: false,
    activeFilters: []
});
provide('filterData', filterData);

const initialUiState = ref({
    [indexName]: {}
});

const setTotalHits = (nbHits: number) => {
    totalHits.value = nbHits;
}

const fetchProductPrices = () => {
    useBevcoFetch(
        apiInstance,
        '/store-api/category/' + currentFilters.navigationId + '/prices',
        {
            method: 'GET'
        }
    ).then((response) => {
        prices.value = response;
    });
}

function onStateChange({uiState, setUiState}) {
    if (uiState[indexName].sortBy) {
        setUiState(uiState);
        return;
    }
    if (uiState[indexName].refinementList) {
        filterData.value.activeFilters = Object.keys(uiState[indexName].refinementList);
    } else {
        filterData.value.activeFilters = [];
    }
    if (uiState[indexName].range) {
        filterData.value.activeFilters.push('price');
    }
    if (uiState[indexName].range && uiState[indexName].range['price.percentage.gross']) {
        let min = parseInt(uiState[indexName].range['price.percentage.gross'].split(':')[0]);
        if (min > 1) {
            min = min - 1;
        }
        uiState[indexName].range['price.percentage.gross'] = min + ":";
    }
    setUiState(uiState);
}

function getListingFilters(listingData) {
    if (!listingData) {
        return [];
    }
    let listingFilters = Object.keys(listingData);
    let propertyFilters = [];

    if (listingFilters.includes('properties') && listingData.properties.hasOwnProperty('entities')) {
        propertyFilters = listingData.properties.entities.map((property: any) => {
            return property.name;
        });
    }
    return listingFilters.concat(propertyFilters);
}

const serverRootMixin = ref(
    createServerRootMixin({
        searchClient: algolia,
        indexName,
        insights: insightsSettings(),
        onStateChange: onStateChange,
        initialUiState: initialUiState,
        enablePersonalization: true,
        authenticatedUserToken: userToken,
        future: {preserveSharedStateOnUnmount: true}
    }),
)
const { instantsearch } = serverRootMixin.value.data()

const renderFn = (renderOptions, isFirstRender) => {};
const sortItems = [
    { value: indexName, label: t('listing.recommended') },
    { value: algoliaConfig.priceAscIndexName, label: t('listing.prisAsc') },
    { value: algoliaConfig.priceDescIndexName, label: t('listing.prisDesc') },
    { value: algoliaConfig.nameAscIndexName, label: t('listing.nameAsc') },
    { value: algoliaConfig.nameDescIndexName, label: t('listing.nameDesc') },
]
const virtualSort = connectSortBy(renderFn);
const virtualRefinement = connectRefinementList(renderFn);
const virtualInfiniteHits = connectInfiniteHits(renderFn);
const virtualRange = connectRange(renderFn);
let widgetArray = [
    virtualSort({items: sortItems}),
    virtualRange({attribute: 'price.percentage.gross'}),
    virtualRange({attribute: 'price.gross'}),
    virtualInfiniteHits({page: 0})
];
for (let attr of availableFilters) {
    let attrName = 'filterableProperties.' + attr;
    widgetArray.push(virtualRefinement({attribute: attrName}))
}
instantsearch.addWidgets(widgetArray);

provide('$_ais_ssrInstantSearchInstance', instantsearch);

const { data: algoliaState } = await useAsyncData('algolia-state', async () => {
    if (process.server) {
        const nuxtApp = useNuxtApp();
        nuxtApp.$algolia.transporter.requester = (
            await import('@algolia/requester-node-http').then(
                (lib) => lib.default || lib
            )
        ).createNodeHttpRequester();
    }
    if (listingElement.streamId !== null) {
        algoliaProductFilterQuery.value = 'streamIds:' + listingElement.streamId;
    } else {
        const category = await algoliaGetObject(algoliaConfig.categoryIndexName, currentFilters.navigationId, (result => {
            return result;
        }));
        if (category.value && category.value.name)
        algoliaProductFilterQuery.value = 'categories: "' + category.value.name + '"';
    }
    algoliaProductFilterQuery.value += ' AND visibilities.ca7a8b4761a844be94f9cbe265179d6a:30';
    return await instantsearch.findResultsState({
        component: {
            $options: {
                components: {
                    AisInstantSearchSsr,
                    AisConfigure,
                    AisInfiniteHits,
                    AisClearRefinements,
                    AisCurrentRefinements,
                    AisRangeInput,
                    AisPanel,
                    AisStats
                },
                data() {
                    return { instantsearch };
                },
                provide: { $_ais_ssrInstantSearchInstance: instantsearch },
                render() {
                    return h(AisInstantSearchSsr, null, () => [
                        // Include any vue-instantsearch components that you use including each refinement attribute
                        h(AisConfigure, {filters: algoliaProductFilterQuery.value, hitsPerPage: 20, clickAnalytics: true}),
                        h('div', {class: 'lg:basis-1/4'}, () => [
                            h(AisClearRefinements),
                            h(AisCurrentRefinements),
                            h(AisPanel, null, () => [
                                availableFilters.map((refinement) =>
                                    h(AisRefinementList, {class:'pb-1', attribute: 'filterableProperties.' + refinement})
                                ),
                                h(AisRangeInput, {class:'pb-1', attribute: 'price.percentage.gross'}),
                                h(AisRangeInput, {class:'pb-1', attribute: 'price.gross'})
                            ])
                        ]),
                        h('div', {class: 'lg:basis-3/4'}, () => [
                            h(AisStats, { class: 'hidden' }, () => [
                                h('template', { slotProps: { nbHits } }, () => [
                                    h('span', null, () => [
                                        '{{setTotalHits(nbHits)}}',
                                    ]),
                                ]),
                            ]),
                            h(AisSortBy, {items: sortItems}),
                            h(AisInfiniteHits, {
                                'class-names': {
                                    'ais-InfiniteHits': 'pt-2',
                                    'ais-InfiniteHits-list': 'grid grid-cols-2 lg:grid-cols-4 gap-2 mt-2 mx-0 px-4 lg:px-0 min-w-0 list-none',
                                    'ais-InfiniteHits-item': 'w-full bg-white b-none m-0 p-0 min-w-0'
                                },
                                'show-previous': true
                            })
                        ]),
                    ]);
                },
            },
        },
        renderToString,
    });
});

onBeforeMount(() => {
    if (algoliaState.value) {
        instantsearch.hydrate(algoliaState.value);
    }
});

onMounted(() => {
    const categoryTotalVarer = document.querySelector('.category-total-varer');

    if (categoryTotalVarer && totalHits.value) {
        categoryTotalVarer.children[0].innerHTML = totalHits.value + ' varer';
    }
    isKey.value = isKey.value + 1;
})

watch(
    () => isLoggedIn.value,
    (value) => {
        if (value) {
            fetchProductPrices();
            userToken.value = user.value?.id;
            refreshItem.value?.refresh();
            isKey.value = isKey.value + 1;
        } else {
            prices.value = [];
            userToken.value = null;
            refreshItem.value?.refresh();
            isKey.value = isKey.value + 1;
        }
    },
    {
        immediate: true,
    }
);

watch(
    () => loadMoreButtonVisibility.value,
    (value) => {
        if (value) {
            loadMoreButton.value.click();
        }
    },
    {
        immediate: true,
    }
);


</script>

<template>
    <div class="container pb-8 lg:px-0 relative" id="list-container">
        <ais-instant-search-ssr
            :key="isKey"
            :insights="insightsSettings()"
            :class-names="{'ais-InstantSearch': ''}"
            :enable-personalization="true"
            :authenticated-user-token="userToken"
        >
            <ais-configure :filters="algoliaProductFilterQuery" :hits-per-page.camel="20" :clickAnalytics="true"/>
            <ais-refresh ref="refreshItem" />
            <SharedAlgoliaFilters
                :listing-filters="getListingFilters(content.data.listing.aggregations)"
            />
            <div class="w-full">
                <div
                    class="
                        flex flex-col md:flex-row md:items-center justify-between
                        w-full box-border
                        md:sticky md:top-[103px] xl:top-[111px] md:z-30 md:pt-[20px] md:pb-[47px] md:mb-[-1px] md:bg-white md:border-b-solid md:border-b md:border-brand-light
                    "
                    id="list-actions"
                >
                    <div class="flex items-center gap-4 px-5 md:px-0 py-5 md:py-0">
                        <h1 v-if="pageTitle" class="text-[16px] md:text-[25px] m-0 font-semibold">{{pageTitle}}</h1>
                        <span v-if="pageTitle" class="text-[16px] md:text-[25px] text-brand-darkGray">|</span>
                        <ais-stats>
                            <template v-slot="{ nbHits }">
                                <span class="text-[16px] md:text-[25px] text-brand-darkGray">{{ nbHits }} {{ $t('general.products') }}</span>
                            </template>
                        </ais-stats>
                    </div>
                    <div class="flex items-center justify-end py-1 md:py-0  px-5 md:px-0 bg-brand-lightGray md:bg-transparent">
                        <ais-sort-by
                            :items="sortItems"
                            :class-names="{ 'ais-SortBy': '' }"
                        >
                            <template v-slot="{ items, currentRefinement, refine }">
                              <span class="font-semibold text-[14px]">{{ $t('listing.sortBy') }}
                                  <select
                                      class="
                                          ais-SortBy-select
                                          focus:outline-none focus:ring-none
                                          bg-transparent border-none
                                          font-medium text-[14px]
                                          w-[90px] md:w-[125px] overflow-hidden text-ellipsis
                                          pr-4
                                      "
                                      :value="currentRefinement"
                                      @change="refine($event.currentTarget.value)"
                                  >
                                      <option
                                          v-for="item in items"
                                          :key="item.value"
                                          :value="item.value"
                                          class="ais-SortBy-option"
                                      >
                                          {{ item.label }}
                                      </option>
                                  </select>
                              </span>
                            </template>
                        </ais-sort-by>
                        <button
                            class="
                                cursor-pointer
                                border-none
                                text-[17px] font-semibold line-height-[1]
                                bg-brand-gray
                                mx-auto md:ml-[35px]
                                py-[15px]
                                px-[25px]
                                rounded-full
                                flex flex-justify-between
                                w-[138px]
                                fixed md:position-initial
                                z-30 md:z-auto
                                bottom-[20px] bottom-reset
                                left-0 md:left-auto
                                right-0 md:right-auto
                            "
                            id="filters-toggle"
                            @click.prevent="filterData.showFilters = !filterData.showFilters"
                        >
                            <span>{{ $t('listing.filter') }}</span><NuxtImg src="/filters.svg" height="18" :alt="$t('listing.filter')" />
                        </button>
                    </div>
                </div>
                <ais-infinite-hits
                    :class-names="{
                        'ais-InfiniteHits': '',
                        'ais-InfiniteHits-list': '' +
                            'grid-container ' +
                            'grid grid-cols-2 gap-0 ' +
                            'md:grid-cols-3 ' +
                            'lg:grid-cols-4 ' +
                            'xl:grid-cols-5 ',
                        'ais-InfiniteHits-item': '' +
                            'bg-white grid-item relative ' +
                            'p-[10px] ' +
                            'md:px-[15px] md:pb-[15px] ' +
                            'lg:px-[20px] ' +
                            'xl:px-[35px]  '
                    }"
                    :show-previous="true"
                >
                    <template v-slot:loadPrevious="{ page, isFirstPage, refinePrevious }">
                        <button
                            v-show="!isFirstPage"
                            @click="refinePrevious()"
                            class="flex btn-link mx-auto justify-center mb-3 underline"
                        >
                            {{ $t('listing.loadPrevious') }}
                        </button>
                    </template>
                    <template v-slot:item="{ item, sendEvent }">
                        <ProductCard
                            :key="item.productNumber"
                            :product="item"
                            :price="prices[item.objectID]"
                            @click="[
                                sendEvent('click', item, 'PLP: Product Clicked'),
                                addSearchHistory(item.objectID, item.__queryID),
                            ]"
                        />
                    </template>
                    <template v-slot:loadMore="{ page, isLastPage, refineNext }">
                        <div class="w-full mt-8">
                            <button
                                ref="loadMoreButton"
                                v-if="!isLastPage"
                                @click="refineNext()"
                                class="flex mx-auto justify-center py-4 px-10 border border-transparent shadow-sm text-xs text-white bg-brand-dark hover:bg-gray-700 focus:outline-none focus:ring-none focus:ring-gray-500"
                            >
                                {{ $t('listing.loadMore') }}
                            </button>
                        </div>
                    </template>
                </ais-infinite-hits>
            </div>
        </ais-instant-search-ssr>
    </div>
</template>
